import React from 'react';
import titleImage from './thelizardroomtitle.png';

export const Title = () => {
  return (
    <div>
      <img className="titleImage" src={titleImage} alt="The Lizard Room" />
    </div>
  )
};
