import React from 'react';
import leftLeafImage from '../images/leftLeaf.png';

export const LeftLeaf = () => {
  return (
    <div>
      <img className="leftLeafImage" src={leftLeafImage} alt="left leaf" />
    </div>
  )
};
