import React from 'react';
import rightLeafImage from '../images/rightLeaf.png';

export const RightLeaf = () => {
  return (
    <div>
       <img className="rightLeafImage" src={rightLeafImage} alt="right leaf" />
    </div>
  )
};
